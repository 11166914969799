import { de } from "./intl/de";
import { en } from "./intl/en";
import { BookingForm } from "./BookingForm";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./style/GlobalStyle";

function App() {
  const language = (window.navigator.userLanguage || window.navigator.language)
    .split("-")[0]
    .toLowerCase();

  const messages = language === "de" ? de : en;
  return (
    <IntlProvider messages={messages} locale={language} key={language}>
      <ThemeProvider theme={{}}>
        <GlobalStyle />
        <BookingForm language={language} />
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
