export const de = {
  "action.reset": "Zurücksetzen",
  "form.firstName": "Vorname *",
  "form.firstName.placeholder": "Max",
  "form.lastName": "Nachname *",
  "form.lastName.placeholder": "Mustermann",
  "form.dateOfBirth": "Geburtsdatum *",
  "form.dateOfBirth.placeholder": "20.12.1980",
  "form.phone": "Mobil-Telefonnummer *",
  "form.phone.placeholder": "+41 123 456789",
  "form.email": "E-Mail-Adresse *",
  "form.email.placeholder": "max@mustermann.ch",
  "form.idNumber": "Ausweisnummer",
  "form.gender": "Geschlecht *",
  "form.gender.placeholder": "Bitte wählen",
  "form.gender.male": "männlich",
  "form.gender.female": "weiblich",
  "form.gender.other": "divers",
  "form.street": "Straße *",
  "form.street.placeholder": "Musterstraße 1",
  "form.city": "Stadt *",
  "form.city.placeholder": "Musterstadt",
  "form.postalCode": "Postleitzahl *",
  "form.postalCode.placeholder": "1234",
  "form.countryCode": "Land *",
  "form.countryCode.placeholder": "Bitte wählen",
  "form.cfpCode": "CFP-Identifikationscode",
  "title.address": "Adresse",
  "title.personalData": "Persönliche Daten",
  "title.contact": "Kontaktdaten",
  "title.cfp": "Covid-Free Pass (optional)",
  "action.send": "Senden",
  "validation.required": "Erforderliches Feld",
  "validation.invalidPhone":
    "Die Telefonnummer muss mit '+' oder '00' und dem Ländercode beginnen.",
  "form.submit.success": "CFP wurde erfolgreich erstellt!",
  "form.submit.error": "CFP konnte nicht erstellt werden.",
  "validation.gtc_required": "Sie müssen die AGB akzeptieren.",
  "validation.privacy_required":
    "Für die Ausstellung eines CFPs müssen Sie der Datenschutzvereinbarung zustimmen.",
  "form.gtc": "Ich akzeptiere die {gtc}. *",
  "form.privacy":
    "Ich stimme zu, dass meine Daten gemäß {privacyPolicy} verarbeitet werden. *",
  gtc: "AGB",
  privacyPolicy: "Datenschutzvereinbarung",
};
