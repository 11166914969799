import React, { useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  SubmitButton,
  Select,
  ResetButton,
  Checkbox,
} from "formik-antd";
import { Row, Col, Divider, message } from "antd";
import { Formik } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { Translate } from "./intl/Translate";
import { useApi } from "./api";
import moment from "moment";
import deCountries from "./intl/countries/de.json";
import enCountries from "./intl/countries/en.json";

const Main = styled.main`
  padding: 16px;

  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0;
  }
`;

export const BookingForm = ({ language }) => {
  const { post } = useApi();
  const { formatMessage } = useIntl();
  const [countries] = useState(language === "de" ? deCountries : enCountries);

  Yup.addMethod(Yup.string, "phone", function (messageError) {
    return this.test(
      "phone",
      messageError,
      (value) => value?.startsWith("00") || value?.startsWith("+")
    );
  });

  const FormSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required(formatMessage({ id: "validation.required" })),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required(formatMessage({ id: "validation.required" })),
    gender: Yup.string().required(formatMessage({ id: "validation.required" })),
    street: Yup.string().required(formatMessage({ id: "validation.required" })),
    city: Yup.string().required(formatMessage({ id: "validation.required" })),
    postalCode: Yup.number().required(
      formatMessage({ id: "validation.required" })
    ),
    countryCode: Yup.string().required(
      formatMessage({ id: "validation.required" })
    ),
    email: Yup.string()
      .email()
      .required(formatMessage({ id: "validation.required" })),
    phone: Yup.string()
      .phone(formatMessage({ id: "validation.invalidPhone" }))
      .required(formatMessage({ id: "validation.required" })),
    dateOfBirth: Yup.string().required(
      formatMessage({ id: "validation.required" })
    ),
    acceptGtc: Yup.bool()
      .required(formatMessage({ id: "validation.required" }))
      .oneOf([true], formatMessage({ id: "validation.gtc_required" })),
    acceptPrivacy: Yup.bool()
      .required(formatMessage({ id: "validation.required" }))
      .oneOf([true], formatMessage({ id: "validation.privacy_required" })),
  });

  return (
    <Main>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          dateOfBirth: "",
          gender: "",
          street: "",
          city: "",
          postalCode: "",
          countryCode: "",
          email: "",
          phone: "",
        }}
        validationSchema={FormSchema}
        onSubmit={async (values) => {
          console.log(values);
          try {
            await post("booking", {
              ...values,
              dateOfBirth: moment(values.dateOfBirth).format("yyyy-MM-DD"),
              language,
            });
            message.success(formatMessage({ id: "form.submit.success" }));
            window.location.href =
              "https://www.c19t.ch/cfp/coronaimpfzentrumbasel/cfp-ok/";
          } catch (error) {
            message.error(formatMessage({ id: "form.submit.error" }));
          }
        }}
      >
        <Form>
          <Divider orientation="left">
            <Translate text="title.personalData" />
          </Divider>
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="firstName"
                labelCol={{ span: 24 }}
                label={formatMessage({ id: "form.firstName" })}
              >
                <Input
                  name="firstName"
                  placeholder={formatMessage({
                    id: "form.firstName.placeholder",
                  })}
                  autoComplete="given-name"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="lastName"
                labelCol={{ span: 24 }}
                label={formatMessage({ id: "form.lastName" })}
              >
                <Input
                  name="lastName"
                  placeholder={formatMessage({
                    id: "form.lastName.placeholder",
                  })}
                  autoComplete="family-name"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="dateOfBirth"
                labelCol={{ span: 24 }}
                label={formatMessage({ id: "form.dateOfBirth" })}
              >
                <DatePicker
                  id="dateOfBirth"
                  name="dateOfBirth"
                  placeholder={formatMessage({
                    id: "form.dateOfBirth.placeholder",
                  })}
                  style={{ width: "100%" }}
                  autoComplete="bday"
                  format="DD.MM.YYYY"
                  mode="date"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="gender"
                labelCol={{ span: 24 }}
                label={formatMessage({ id: "form.gender" })}
              >
                <Select
                  id="gender"
                  name="gender"
                  placeholder={formatMessage({ id: "form.gender.placeholder" })}
                  style={{ width: "100%" }}
                  autoComplete="gender"
                >
                  <Select.Option value="female" key="female">
                    {formatMessage({ id: "form.gender.female" })}
                  </Select.Option>
                  <Select.Option value="male" key="male">
                    {formatMessage({ id: "form.gender.male" })}
                  </Select.Option>
                  <Select.Option value="other" key="other">
                    {formatMessage({ id: "form.gender.other" })}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">
            <Translate text="title.contact" />
          </Divider>
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="email"
                labelCol={{ span: 24 }}
                label={formatMessage({ id: "form.email" })}
              >
                <Input
                  name="email"
                  placeholder={formatMessage({ id: "form.email.placeholder" })}
                  type="email"
                  autoComplete="email"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="phone"
                labelCol={{ span: 24 }}
                label={formatMessage({ id: "form.phone" })}
              >
                <Input
                  name="phone"
                  placeholder={formatMessage({ id: "form.phone.placeholder" })}
                  type="phone"
                  autoComplete="tel"
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">
            <Translate text="title.address" />
          </Divider>
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="countryCode"
                labelCol={{ span: 24 }}
                label={formatMessage({ id: "form.countryCode" })}
              >
                <Select
                  name="countryCode"
                  placeholder={formatMessage({
                    id: "form.countryCode.placeholder",
                  })}
                  autoComplete="off"
                >
                  {countries.map((country) => (
                    <Select.Option value={country.alpha2} key={country.alpha2}>
                      {country.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="street"
                labelCol={{ span: 24 }}
                label={formatMessage({ id: "form.street" })}
              >
                <Input
                  name="street"
                  placeholder={formatMessage({ id: "form.street.placeholder" })}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" xs={8} sm={6}>
              <Form.Item
                name="postalCode"
                labelCol={{ span: 24 }}
                label={formatMessage({ id: "form.postalCode" })}
              >
                <Input
                  name="postalCode"
                  placeholder={formatMessage({
                    id: "form.postalCode.placeholder",
                  })}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={16} sm={18}>
              <Form.Item
                name="city"
                labelCol={{ span: 24 }}
                label={formatMessage({ id: "form.city" })}
              >
                <Input
                  name="city"
                  placeholder={formatMessage({ id: "form.city.placeholder" })}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row">
              <Form.Item name="acceptGtc">
                <Checkbox name="acceptGtc">
                  <Translate
                    text="form.gtc"
                    values={{
                      gtc: (
                        <a
                          target="_blank"
                          href="https://www.c19t.ch/cfp/coronaimpfzentrumbasel/agb"
                          rel="noopener noreferrer"
                        >
                          <Translate text="gtc" />
                        </a>
                      ),
                    }}
                  />
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row">
              <Form.Item name="acceptPrivacy">
                <Checkbox name="acceptPrivacy">
                  <Translate
                    text="form.privacy"
                    values={{
                      privacyPolicy: (
                        <a
                          target="_blank"
                          href="https://www.c19t.ch/cfp/coronaimpfzentrumbasel/datenschutz"
                          rel="noopener noreferrer"
                        >
                          <Translate text="privacyPolicy" />
                        </a>
                      ),
                    }}
                  />
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row">
              <SubmitButton type="primary">
                <Translate text="action.send" />
              </SubmitButton>
            </Col>
            <ResetButton>
              <Translate text="action.reset" />
            </ResetButton>
          </Row>
        </Form>
      </Formik>
    </Main>
  );
};
