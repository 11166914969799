import { useCallback, useMemo } from "react";
import queryString from "query-string";

const CONTENT_TYPE_APPLICATION_JSON = "application/json";

export const getBaseUrl = () => {
  const env = process.env.REACT_APP_KEYPER_ENV
    ? process.env.REACT_APP_KEYPER_ENV.toLowerCase()
    : null;

  switch (env) {
    case "develop":
      return "https://api.dev.keyper.net/v1/connect/hps";
    case "staging":
      return "https://api.sta.keyper.net/v1/connect/hps";
    case "sandbox":
      return "https://api.sbx.keyper.net/v1/connect/hps";
    case "production":
      return "https://api.keyper.net/v1/connect/hps";
    default:
      return "http://localhost:29755";
  }
};

export const DefaultHeaders = {
  "Content-Type": CONTENT_TYPE_APPLICATION_JSON,
};

export const useApi = () => {
  const queries = queryString.parse(window.location.search);
  const token = queries.clientId;

  let headers = useMemo(
    () =>
      token
        ? {
            ...DefaultHeaders,
            Authorization: `${token}`,
          }
        : DefaultHeaders,
    [token]
  );

  function get(url, _headers = headers) {
    return getApi(url, _headers);
  }

  function post(url, body, _headers = headers) {
    return postApi(url, body, _headers);
  }

  function put(url, body, _headers = headers) {
    return putApi(url, body, _headers);
  }

  function del(url, _headers = headers) {
    return deleteApi(url, _headers);
  }

  return {
    post: useCallback(post, [headers]),
    get: useCallback(get, [headers]),
    put: useCallback(put, [headers]),
    del: useCallback(del, [headers]),
  };
};

const postApi = (url, body, headers) => {
  const requestOptions = {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  };

  return fetch(`${getBaseUrl()}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // Check if response has body
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes(CONTENT_TYPE_APPLICATION_JSON)) {
        return response.json();
      }
    } else {
      return response.json().then((json) => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return json;
      });
    }
    return null;
  });
};

const putApi = (url, body, headers) => {
  const requestOptions = {
    method: "PUT",
    headers,
    body: JSON.stringify(body),
  };

  return fetch(`${getBaseUrl()}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // Check if response has body
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes(CONTENT_TYPE_APPLICATION_JSON)) {
        return response.json();
      }
    } else {
      throw new Error("Request failed");
    }
    return null;
  });
};

const getApi = (url, headers) => {
  const requestOptions = {
    method: "GET",
    headers,
  };

  return fetch(`${getBaseUrl()}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // Check if response has body
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes(CONTENT_TYPE_APPLICATION_JSON)) {
        return response.json();
      }
    } else {
      throw new Error("Request failed");
    }
    return null;
  });
};

const deleteApi = (url, headers) => {
  const requestOptions = {
    method: "DELETE",
    headers,
  };

  return fetch(`${getBaseUrl()}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // Check if response has body
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes(CONTENT_TYPE_APPLICATION_JSON)) {
        return response.json();
      }
    } else {
      throw new Error("Request failed");
    }
    return null;
  });
};
